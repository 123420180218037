import { Component } from "react";
import { Col, Container, Row, Alert } from "react-bootstrap";
import { BsTelephone } from "react-icons/bs";
import { BsEnvelope } from "react-icons/bs";
import { BiMap } from "react-icons/bi";
import Reaptcha from "reaptcha";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      hour: "",
      emailSent: false,
      captchaOk: false,
    };

    this.handleName = this.handleName.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleHour = this.handleHour.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleEmailSent = this.toggleEmailSent.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleName(event) {
    this.setState({ name: event.target.value });
  }

  handlePhone(event) {
    this.setState({ phone: event.target.value });
  }

  handleHour(event) {
    this.setState({ hour: event.target.value });
  }

  toggleEmailSent() {
    this.setState({ emailSent: true, captchaOk: false });
  }

  onChange(value) {
    this.setState({ captchaOk: true });
  }

  handleSubmit(event) {
    let emailModel = {
      Name: this.state.name,
      Phone: this.state.phone,
      Hour: this.state.hour,
    };

    fetch("email", {
      method: "POST",
      body: JSON.stringify(emailModel),
      headers: { "Content-type": "application/json" },
    }).then((data) => this.toggleEmailSent());

    event.preventDefault();
  }

  render() {
    return (
      <>
        <footer className="footer headding" id="Contact">
          <div className="footer-head">
            <Container>
              <div className="py-5 text-center">
                <iframe
                  src="https://maps.google.com/maps?q=gmina%20zabierz%C3%B3w&t=&z=10&ie=UTF8&iwloc=&output=embed"
                  width="100% auto"
                  height="350px"
                  frameBorder="0"
                  title="Iframe"
                  aria-hidden="false"
                  tabIndex="0"
                />
              </div>              
            </Container>
          </div>
        </footer>
        <div className="f-link">Copyright © 2021. All Rights Reserved.</div>
      </>
    );
  }
}
