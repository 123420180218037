import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'

const EducationSkills = () => {
  return (
    <>
    <div id="EducationSkills" className="educationSkill headding">
              <h1 className="text-center title">
                Cennik
              </h1>
            </div>
            <div>
            <Container>
                <Row className="education-box-main">
                  <Col data-aos="zoom-in"
                  data-aos-delay="100" lg={4} md={4} className="educationColBox" >
                    <div className="education-box">
                      <div className="educontion">
                        <h6>40 minut</h6>
                        <h2>Masaż pleców</h2>
                        <h3>120 zł</h3>
                      </div>
                      <hr />
                      <div className="educontion">
                      <h6>60 minut</h6>
                        <h2>Masaż klasyczny</h2>
                        <h3>150 zł</h3>
                      </div>
                      <hr />
                      <div className="educontion">
                      <h6>80 minut</h6>
                        <h2>Masaż klasyczny</h2>
                        <h3>200 zł</h3>
                      </div>
                    </div>
                  </Col><Col data-aos="zoom-in"
                  data-aos-delay="100" lg={4} md={4} className="educationColBox" >
                    <div className="education-box">
                      
                      <div className="educontion">
                      <h6>40 minut</h6>
                        <h2>Rehabilitacja domowa</h2>
                        <h3>120 zł</h3>
                      </div>
                      <hr />                   
                      <div className="educontion">
                                  <h6>60 minut</h6>
                                  <h2>Rehabilitacja domowa</h2>
                        <h3>150 zł</h3>
                              </div>
                              <hr /><div className="educontion">
                                  <h6>75 minut</h6>
                                  <h2>Masaż gorącymi kamieniami</h2>
                                  <h3>220 zł</h3>
                              </div>
                    </div>
                  </Col><Col data-aos="zoom-in"
                  data-aos-delay="100" lg={4} md={4} className="educationColBox" >
                    <div className="education-box">
                      
                      <div className="educontion">
                      <h6>75 minut</h6>
                                  <h2>Masaż stemplami ziołowymi</h2>
                        <h3>220 zł</h3>
                      </div>
                              <hr />
                              <div className="educontion">
                                  <h6>60 minut</h6>
                                  <h2>Masaż ciążowy</h2>
                                  <h3>150 zł</h3>
                              </div>
                              <hr />
                      <div className="educontion">
                      <h6>jedna aplikacja</h6>
                        <h2>Kinesiotaping</h2>
                        <h3>50 zł</h3>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
    </>
  )
}

export default EducationSkills