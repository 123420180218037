import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import Aboutus from "../../../assets/Images/About/sample.jpg";

const About = () => {
  return (
    <>
      <div className="container" id="About">
        <div className="about headding " data-aos="fade-right">
          <Row className="about-main">
            <Col md={5}>
              <div className="about-img ">
                <Image
                  data-aos="flip-right"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                  className="d-block img-fluid mx-auto rounded-circle"
                  src={Aboutus}
                />
                <div className="text-center About-name">
                  <h6>Małgorzata Kłapyta</h6>
                  <p>Fizjoterapeutka</p>
                </div>
              </div>
            </Col>
            <Col md={7} className="abouttextbox">
              <div>
                <h1 className="about-title">O mnie</h1>
                <p className="about-subtitle">
                Nazywam się Małgorzata Kłapyta. Jestem fizjoterapeutką i masażystką. W 2015 roku
ukończyłam Akademię Wychowania Fizycznego w Krakowie na kierunku fizjoterapia. W
2017 roku uchwałą Krajowej Rady Fizjoterapeutów uzyskałam prawo wykonywania zawodu
fizjoterapeuty nr 7373.
                              </p>
                              <p className="about-subtitle">
                              Pracuję z pacjentami z chorobami neurologicznymi, z bólem przewlekłym różnego pochodzenia, po urazach i operacjach ortopedycznych,
                              z obrzękami różnego pochodzenia.
                              </p>

                              <p className="about-subtitle">
                              W 2023 roku ukończyłam kurs wczesnej rehabilitacji po udarze poprzedzony wiedzą zdobytą w programie edukacyjnym w zakresie złożonej problematyki pacjentów po udarze.
                              </p>
                <p className="about-subtitle">
                Od 2016 roku jestem w bazie małopolskich certyfikowanych fizjoterapeutów dla osób z
chorobami nerwowo mięśniowymi. Od 6 lat współpracuję z pacjentami neurologicznymi.
                </p>

                <p className="about-subtitle">
                W 2015 roku ukończyłam kurs kinesiotapingu medycznego, bardzo często swoją pracę
uzupełniam tą metodą terapeutyczną.
                </p>

                <p className="about-subtitle">
                Od 2014 roku jestem dyplomowaną masażystką. Ukończyłam kursy masażu gorącymi
kamieniami, stemplami ziołowymi, bańką chińską, hinduski twarzy i głowy, prenatalny -
ciążowy, rewitalizujący oraz według Shantala. Ukończyłam również kurs refleksoterapii
stopy.
                </p>

                <p className="about-subtitle">
                Prywatnie jestem mamą trójki dzieci, uwielbiam spędzać wolny czas w gronie rodzinnym. Lubię spędzać czas aktywnie, zwiedzać i odkrywać najbliższe okolice.
                </p>

              </div>
              <div className="about-psl-info">
                <h1 className="about-sub-info">Informacje</h1>
                <Row>
                  <Col>
                    <div className=" about-list">
                      <span className="perName1">Telefon : </span>
                      <span className="perName2">536-376-438</span>
                    </div>
                    <div className=" about-list">
                      <span className="perName1">Facebook : </span>
                      <span className="perName2">https://www.facebook.com/masago.terapia</span>
                    </div>
                    <div className=" about-list">
                      <span className="perName1">E-mail : </span>
                      <span className="perName2">masago.terapia@gmail.com</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default About;
