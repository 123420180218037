import React, { useEffect } from "react";
import Banner from "../../../assets/Images/Banner/Banner.png";
import Aos from "aos";
import "aos/dist/aos.css";
const HeaderBanner = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div id="Home">
        <div className="banner-img">
          <span className=" ">
            <div className="">
              <img
                src={Banner}
                className=" img-fluid  mx-auto width: 100% "
                alt="Banner"
              ></img>
            </div>
          </span>
          <div className="img-bnr-text ">
            <div>
              <h1 data-aos="flip-down">Masago</h1>
                          <h2 data-aos="flip-down">mobilny gabinet rehabilitacji<br />gabinet masażu leczniczego i relaksacyjnego</h2><br />
              <p data-aos="fade-up">
                              Usługi rehabilitacji domowej świadczę na terenie Bolechowic i okolic.<br />
                              Usługi masażu leczniczego i relaksacyjnego świadczę w Bolechowicach ul. Polna 1.<br />
                              Wybierz usługę, która najbardziej pasuje do Twoich aktualnych potrzeb.<br />
                              Zarezerwuj termin poprzez kontakt SMS, mailowy lub wiadomość na Facebooku.<br />
                              Do masażu przygotuj jedynie ręcznik do okrycia.<br />

              </p><br />
              <p data-aos="fade-up">
                                            Cała reszta w moich rękach.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderBanner;
