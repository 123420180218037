import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import ThemeLogo from "./../../assets/Images/masago_logo.png";
import Links from "./Links";
// import Links from './Link';
const Header = () => {
  return (
    <>
      <div className="header">
        
        <Navbar collapseOnSelect expand="lg">
         
            <Navbar.Brand href="#home" className="logo">
              <img
                src={ThemeLogo}
                alt="themeLogo"
                width="215" height="93"
                className="img-fluid mx-auto"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end nav-menu">
              <Nav className="ms-auto font">
                <Links href="#Home">Strona główna</Links>
                <Links href="#About">O nas</Links>
                <Links href="#Service">Usługi</Links>
                <Links href="#Experience">Doświadczenie</Links>
                <Links href="#EducationSkills">Cennik</Links>
                <Links href="#Contact">Kontakt</Links>
              </Nav>
            </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  );
};

export default Header;
