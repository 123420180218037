import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'

const Experience = () => {
  return (
    <>
      <div id="Experience" className="exp-main headding">
                    <div className="exp ">
                        <h1 className="text-center title">Doświadczenie</h1>
                    </div>
                    <Container >
                        <Row >
                            <Col lg={6} md={6} sm={6}>
                                <div className="exp-card" data-aos="flip-left">
                                    <Card className="exp-block">
                                        <Card.Body className="exp-body">
                                            <Card.Title className="exp-title">Akademia wychowania fizycznego w Krakowie - kierunek fizjoterapia
                                                <p>
                                                    2015
                                                </p>
                                            </Card.Title>
                                            <hr />
                                            <Card.Text className="exp-text">
                                            Od 2016 roku pracuję w zawodzie jako fizjoterapeutka. Na co dzień współpracuję z pacjentami z przewlekłym bólem, neurologicznymi, po urazach i wypadkach. Współpracowałam z kobietami po mastektomii usuwając skutecznie obrzęki
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6}>
                                <div className="exp-card" data-aos="flip-left">
                                    <Card className="exp-block">
                                        <Card.Body className="exp-body">
                                            <Card.Title className="exp-title">Masaż klasyczny i relaksacyjny
                                                <p>
                                                    2014 - teraz
                                                </p>
                                            </Card.Title>
                                            <hr />
                                            <Card.Text className="exp-text">
                                            Od 2014 roku wykonuję masaże klasyczne oraz relaksacyjne. Ukończyłam kursy masażu kamieniami, stemplami i bańką chińską. Uwielbiam masaże gorącymi kamieniami i aromatycznymi stemplami ziołowymi.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6}>
                                <div className="exp-card" data-aos="flip-left">
                                    <Card className="exp-block">
                                        <Card.Body className="exp-body">
                                            <Card.Title className="exp-title">Kinesiotaping
                                                <p>
                                                    2015
                                                </p>
                                            </Card.Title>
                                            <hr />
                                            <Card.Text className="exp-text">
                                            Ukończyłam kurs tapingu rehabilitacyjno - sportowego. W swojej pracy często używam tej metody terapeutycznej w celu osiągnięcia lepszych rezultatów i przedłużenia efektów rehabilitacji
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6}>
                                <div className="exp-card" data-aos="flip-left">
                                    <Card className="exp-block">
                                        <Card.Body className="exp-body">
                                            <Card.Title className="exp-title">Polski język migowy
                                                <p>
                                                    2017
                                                </p>
                                            </Card.Title>
                                            <hr />
                                            <Card.Text className="exp-text">
                                            Ukończyłam trzy stopniowy kurs polskiego języka migowego. Potrafię porozumieć się w stopniu podstawowym z osobami głuchymi. Jesteś niesłyszący? Skontaktuj się ze mną, pomogę Ci.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                            
                            <Col lg={6} md={6} sm={6}>
                                <div className="exp-card" data-aos="flip-left">
                                    <Card className="exp-block">
                                        <Card.Body className="exp-body">
                                            <Card.Title className="exp-title">Masaż prenatalny ciążowy, rewitalizujący i wg Shantala
                                                <p>
                                                    2015
                                                </p>
                                            </Card.Title>
                                            <hr />
                                            <Card.Text className="exp-text">
                                            Ukończyłam kurs masażu kobiet w ciąży. Od 2015 roku wykonuję masaże ciążowe. Jeśli jesteś w ciąży i chcesz zadbać o swoje ciało i szybki powrót do aktywności po ciąży, zarezerwuj termin na masaż prenatalny. Posiadam specjalne kształtki dzięki którym wygodnie położysz się na stole i odpoczniesz.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                            
                            <Col lg={6} md={6} sm={6}>
                                <div className="exp-card" data-aos="flip-left">
                                    <Card className="exp-block">
                                        <Card.Body className="exp-body">
                                      <Card.Title className="exp-title">Kurs wczesnej rehabilitacji po udarze
                                                <p>
                                                    2023
                                                </p>
                                            </Card.Title>
                                            <hr />
                                            <Card.Text className="exp-text">
                                          Ukończyłam kurs wczesnej rehabilitacji po udarze poprzedzony wiedzą zdobytą w kilkudziesięcio godzinnym programie edukacyjnym w zakresie złożonej problematyki pacjentów po udarze.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
    </>
  )
}

export default Experience