import React, { useEffect } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import I1 from "../../../assets/Images/Services/I1.png";
import I2 from "../../../assets/Images/Services/I2.png";
import I3 from "../../../assets/Images/Services/I3.png";
import I4 from "../../../assets/Images/Services/I4.png";
import Aos from "aos";
import "aos/dist/aos.css";
const Service = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="main-service headding" id="Service">
        <Container>
          <div className="service ">
            <h1 className="text-center service-title">Moje usługi</h1>
            <Row className="g-4">
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="service-block"
                          >
                              <Card
                                  className="service-card"
                                  data-aos="fade-up"
                                  data-aos-duration="700"
                              >
                                  <Card.Body>
                                      <Card.Title>
                                          <img
                                              alt="serviceImage"
                                              className="img-fluid mx-auto"
                                              src={I2}
                                          />
                                      </Card.Title>
                                      <Card.Subtitle className="cardtitle">
                                          Rehabilitacja
                                      </Card.Subtitle>
                                      <Card.Text className="service-text">
                                          Skontaktuj się z nami w celu ustalenia celu i sposobu rehabilitacji. Wykorzystujemy terapię manualną, kinezyterapię czyli ćwiczenia oraz kinesiotaping.<br />
                                      </Card.Text>
                                  </Card.Body>
                              </Card>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="service-block"
                          >


                              <Card
                                  className="service-card"
                                  data-aos="fade-up"
                                  data-aos-duration="700"
                              >
                                  <Card.Body>
                                      <Card.Title>
                                          <img
                                              alt="serviceImage"
                                              className="img-fluid mx-auto"
                                              src={I1}
                                          />
                                      </Card.Title>
                                      <Card.Subtitle className="cardtitle ">
                                          Masaż
                                      </Card.Subtitle>
                                      <Card.Text className="service-text">
                                         Masaż leczniczy wspomaga proces rehabilitacji.<br />Zmniejsza wiele objawów towarzyszącym chorobom.<br />Rozluźnia napięcie mięśni.
                                      </Card.Text>
                                  </Card.Body>
                              </Card>

              </Col>
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="service-block"
              >
                <Card
                  className="service-card"
                  data-aos="fade-up"
                  data-aos-duration="700"
                >
                  <Card.Body>
                    <Card.Title>
                      <img
                        alt="serviceImage"
                        className="img-fluid mx-auto"
                        src={I3}
                      />
                    </Card.Title>
                    <Card.Subtitle className="cardtitle">
                                          Drenaż limfatyczny
                    </Card.Subtitle>
                    <Card.Text className="service-text">
                                          Drenaż limfatyczny oddziałuje na układ chłonny zmniejszając obrzęki różnego pochodzenia
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="service-block"
              >
                <Card
                  className="service-card"
                  data-aos="fade-up"
                  data-aos-duration="700"
                >
                  <Card.Body>
                    <Card.Title>
                      <img
                        alt="serviceImage"
                        className="img-fluid mx-auto"
                        src={I4}
                      />
                    </Card.Title>
                    <Card.Subtitle className="cardtitle">
                      Masaż dla kobiet w ciąży
                    </Card.Subtitle>
                    <Card.Text className="service-text">
                                          Masaż dla kobiet w ciąży przeznaczony dla kobiet od 12 tygodnia ciąży, bezpieczny, przynoszący wiele korzyści dla matki i dziecka.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>              
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Service;
